<template>
      <div class="bible-container" v-if="passages">
          <div
            v-for="passage in passages"
            :key="passage"
            class="passage"
            v-html="passage"
          />
        </div>
</template>

<script>
export default {
  data () {
    return {
      passages: null
    }
  },
  async mounted () {
    this.passages = await fetch('https://us-central1-south-sound-foursquare.cloudfunctions.net/ss4-esv-api-v2-dev-getDailyVerses').then(res => res.json()).then(res => res.passages)
  }
}
</script>

<style lang="scss" scoped>
.bible-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.passage{
max-width: 450px;
font-size:14pt;
}
.passage /deep/ .verse-num {
  vertical-align: super;
  font-size: 7pt;
  color: #ababab;
}

</style>
